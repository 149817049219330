import Vue from "vue";
import { extend, localize, ValidationObserver, ValidationProvider } from "vee-validate";
import { required, confirmed, between, max, min, numeric, email } from "vee-validate/dist/rules";

extend("required", required);
extend("confirmed", confirmed);
extend("between", between);
extend("max", max);
extend("min", min);
extend("numeric", numeric);
extend("email", email);
extend("minmax", {
  validate(value, { min, max }) {
    return value.length >= min && value.length <= max;
  },
  params: ["min", "max"]
});

localize("zh_CN", {
  name: "zh_CN",
  names: {
    account: "A账号",
    password: "A密码",
    confirmPwd: "A确认密码",
    code: "A验证码",
    phone: "A电话",
    userName: "A姓名",
    bannerName: "A广告名称",
    startDate: "A开始时间",
    displayDays: "A展示天数",
    newsName: "A文章标题",
    newsRemark: "A文章摘要",
    partsName: "A部件名称",
    title: "A标题",
    subtitle: "A副标题",
    machineryName: "A设备名称",
    machineryModel: "A换代型号",
    remarks: "A备注",
    sortIndex: "A下标",
    typeName: "A分类名称",
    typeMemo: "A分类描述",
    typeTableName: "A配件参数表头",
    agentName: "A代理商名称",
    agentPointName: "A网点名称",
    address: "A地址",
    introduce: "A介绍信息",
    lecturerName: "A讲师",
    workYear: "A工作经验",
    firmName: "A商会名称",
    presidentName: "A会长名称",
    vicePresidentName: "A副会长名称",
    commissarName: "A政委名称",
    videoTitle: "A课程名称",
    videoTime: "A播放时长",
    versionCode: "A版本号",
    versionName: "A版本名称",
    versionContent: "A更新说明",
    email: "A邮箱",
    partsMemo: "A配件说明",
    videoPrice: "A课程价格",
    modelName: "A型号名称",
    machinery: "B设备",
    province: "B省份",
    city: "B城市",
    area: "B地区",
    versionType: "B更新包类型",
    updateType: "B更新方式",
    pinType: "B归属",
    faultCode: "A故障代码",
    price: "A价格",
    num: "A数量"
  },
  fields: {
    password: {
      minmax: "密码必须在{min}与{max}个字符之间"
    },
    confirmPwd: {
      confirmed: "两次输入的密码不一致"
    },
    bannerName: {
      minmax: "Banner名称由{min}～{max}个字符组成"
    },
    newsName: {
      minmax: "文章标题由{min}～{max}个字符组成"
    },
    newsRemark: {
      minmax: "文章摘要由{min}～{max}个字符组成"
    },
    partsName: {
      minmax: "部件名称由{min}～{max}个字符组成"
    },
    title: {
      max: "标题最大不能超过{length}个字符"
    },
    subtitle: {
      minmax: "副标题由{min}～{max}个字符组成",
      max: "副标题最大不能超过{length}个字符"
    },
    machineryName: {
      minmax: "设备名称由{min}～{max}个字符组成"
    },
    machineryModel: {
      max: "换代型号最大不能超过{length}个字符"
    },
    remarks: {
      max: "备注最大不能超过{length}个字符"
    },
    typeName: {
      max: "分类名称最大不能超过{length}个字符"
    },
    typeMemo: {
      max: "分类描述最大不能超过{length}个字符"
    },
    typeTableName: {
      max: "配件参数表头最大不能超过{length}个字符"
    },
    address: {
      max: "地址信息最大不能超过{length}个字符"
    },
    introduce: {
      max: "介绍信息最大不能超过{length}个字符"
    },
    lecturerName: {
      max: "讲师名称最大不能超过{length}个字符"
    },
    firmName: {
      max: "商会名称最大不能超过{length}个字符"
    },
    presidentName: {
      max: "会长名称最大不能超过{length}个字符"
    },
    vicePresidentName: {
      max: "副会长名称最大不能超过{length}个字符"
    },
    commissarName: {
      max: "政委名称最大不能超过{length}个字符"
    },
    videoTitle: {
      max: "课程名称最大不能超过{length}个字符"
    },
    videoTime: {
      numeric: "播放时长只能输入整数"
    },
    versionCode: {
      numeric: "版本号只能输入整数"
    },
    partsMemo: {
      max: "配件说明最大不能超过{length}个字符"
    },
    email: {
      email: "邮箱格式错误"
    },
    modelName: {
      max: "型号名称最大不能超过{length}个字符"
    }
  },
  messages: {
    required: field => field.substr(0, 1) === "A" ? field.replace("A", "请输入") : field.replace("B", "请选择")
  }
});

extend("account", {
  message: () => '账号由6～18位小写字母、数字或"_"组成，且首位必须是字母',
  validate: value => value.length >= 6 && value.length <= 18 && /^[a-z][a-z_0-9]{5,17}$/.test(value)
});
extend("phone", {
  message: () => "手机号码不符合规则",
  validate: value => value.length === 11 && /^1\d{10}$/.test(value)
});
extend("telephone", {
  message: () => "电话号码不符合规则",
  validate: value => /^1\d{10}$/.test(value) || /^\d{3,4}-\d{7,8}$/.test(value)
});
extend("sortIndex", {
  message: () => "下标只能输入小于等于两位的整数",
  validate: value => /^\d{1,2}?$/.test(value)
});
extend("workYear", {
  message: () => "只能输入1~99的整数",
  validate: value => /^\d{1,2}?$/.test(value)
});
extend("department", {
  message: () => "部门名称由2～10位字母、数字或中文组成",
  validate: value => value.length >= 2 && value.length <= 10 && /^[a-zA-Z0-9\u4e00-\u9fa5]{2,10}$/.test(value)
});
extend("userName", {
  message: () => "用户名称由2～10位字母、数字或中文组成",
  validate: value => value.length >= 2 && value.length <= 10 && /^[a-zA-Z0-9\u4e00-\u9fa5]{2,10}$/.test(value)
});
extend("objName", {
  message: () => "名称只能由字母、数字或中文组成",
  validate: value => /^[a-zA-Z0-9\u4e00-\u9fa5]+$/.test(value)
});
extend("amount", {
  message: () => "请输入有效金额，金额最多只支持两位小数",
  validate: value => /^(([1-9]\d{0,7})|(0))(\.\d{1,2})?$/.test(value)
});
extend("num", {
  message: () => "请输入整数",
  validate: value => /^\d+$/.test(value)
});

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
