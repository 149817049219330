import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  } else {
    return originalPush.call(this, location).catch(err => err);
  }
}

VueRouter.prototype.replace = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalReplace.call(this, location, onResolve, onReject);
  } else {
    return originalReplace.call(this, location).catch(err => err);
  }
}

const routes = [
    {
      path: '/',
      component: Home,
      meta: { requireAuth: true },
      children: [
        { path: "/", name: "Main", meta: { requireAuth: true }, component: () => import("../pages/Main") },
        { path: "/groups", name: "Groups", meta: { requireAuth: true }, component: () => import("../pages/manager/Groups") },
        { path: "/managers", name: "Managers", meta: { requireAuth: true }, component: () => import("../pages/manager/Managers") },

        { path: "/settled/members", name: "Members", meta: { requireAuth: true }, component: () => import("../pages/settled/Members") },
        { path: "/settled/merchants", name: "MerchantSettled", meta: { requireAuth: true }, component: () => import("../pages/settled/MerchantSettled") },
        { path: "/settled/providers", name: "ProviderSettled", meta: { requireAuth: true }, component: () => import("../pages/settled/ProviderSettled") },
        { path: "/settled/partners", name: "Partners", meta: { requireAuth: true }, component: () => import("../pages/content/Partners") },
        { path: "/settled/finances", name: "Finances", meta: { requireAuth: true }, component: () => import("../pages/content/Finances") },
        { path: "/settled/masters", name: "Masters", meta: { requireAuth: true }, component: () => import("../pages/content/Masters") },
        { path: "/settled/insurers", name: "Insurers", meta: { requireAuth: true }, component: () => import("../pages/content/Insurers") },

        { path: "/operate/banners", name: "Banners", meta: { requireAuth: true }, component: () => import("../pages/operate/Banners") },
        { path: "/operate/dynamics", name: "Dynamics", meta: { requireAuth: true }, component: () => import("../pages/operate/Dynamics") },
        { path: "/operate/machines", name: "Machines", meta: { requireAuth: true }, component: () => import("../pages/operate/Machines") },
        { path: "/operate/parts", name: "Parts", meta: { requireAuth: true }, component: () => import("../pages/operate/Parts") },
        { path: "/operate/accessories", name: "Accessories", meta: { requireAuth: true }, component: () => import("../pages/operate/Accessories") },
        { path: "/operate/leases", name: "Leases", meta: { requireAuth: true }, component: () => import("../pages/operate/Leases") },
        { path: "/operate/agencies", name: "Agencies", meta: { requireAuth: true }, component: () => import("../pages/operate/Agencies") },
        { path: "/operate/recruits", name: "Recruits", meta: { requireAuth: true }, component: () => import("../pages/operate/Recruits") },
        { path: "/operate/manufacturers", name: "Manufacturers", meta: { requireAuth: true }, component: () => import("../pages/operate/Manufacturers") },
        { path: "/operate/communications", name: "Communications", meta: { requireAuth: true }, component: () => import("../pages/operate/Communications") },
        { path: "/operate/old/parts", name: "OldParts", meta: { requireAuth: true }, component: () => import("../pages/operate/OldParts") },

        { path: "/content/news", name: "News", meta: { requireAuth: true }, component: () => import("../pages/content/News") },
        { path: "/content/news/add", name: "NewsAdd", meta: { requireAuth: true }, component: () => import("../pages/content/NewsAdd") },
        { path: "/content/news/edit", name: "NewsEdit", meta: { requireAuth: true }, component: () => import("../pages/content/NewsEdit") },
        { path: "/content/news/comments", name: "NewsComments", meta: { requireAuth: true }, component: () => import("../pages/content/NewsComments") },
        { path: "/content/brands", name: "Brands", meta: { requireAuth: true }, component: () => import("../pages/content/Brands") },
        { path: "/content/parts", name: "PartsBases", meta: { requireAuth: true }, component: () => import("../pages/content/PartsBases") },
        { path: "/content/brand/comments", name: "BrandComments", meta: { requireAuth: true }, component: () => import("../pages/content/BrandComments") },
        { path: "/content/bp/comments", name: "BrandPartsComments", meta: { requireAuth: true }, component: () => import("../pages/content/BrandPartsComments") },
        { path: "/content/machinery", name: "Machinery", meta: { requireAuth: true }, component: () => import("../pages/content/Machinery") },
        { path: "/content/bpg", name: "BrandPartsGroup", meta: { requireAuth: true }, component: () => import("../pages/content/BrandPartsGroup") },
        { path: "/content/bpt", name: "BrandPartsType", meta: { requireAuth: true }, component: () => import("../pages/content/BrandPartsType") },
        { path: "/content/bp", name: "BrandParts", meta: { requireAuth: true }, component: () => import("../pages/content/BrandParts") },
        { path: "/content/bpa", name: "BrandPartsAttributes", meta: { requireAuth: true }, component: () => import("../pages/content/BrandPartsAttributes") },
        { path: "/content/mt", name: "ManufacturerTypes", meta: { requireAuth: true }, component: () => import("../pages/content/ManufacturerTypes") },
        { path: "/content/diagram", name: "MachineryDiagram", meta: { requireAuth: true }, component: () => import("../pages/content/MachineryDiagram") },
        { path: "/content/atlas", name: "MachineryAtlas", meta: { requireAuth: true }, component: () => import("../pages/content/MachineryAtlas") },
        { path: "/content/agents", name: "Agents", meta: { requireAuth: true }, component: () => import("../pages/content/Agents") },
        { path: "/content/agents/comments", name: "AgentsComments", meta: { requireAuth: true }, component: () => import("../pages/content/AgentsComments") },
        { path: "/content/lectures", name: "Lectures", meta: { requireAuth: true }, component: () => import("../pages/content/Lectures") },
        { path: "/content/lecture/comments", name: "LecturerComments", meta: { requireAuth: true }, component: () => import("../pages/content/LecturerComments") },
        { path: "/content/videos", name: "Videos", meta: { requireAuth: true }, component: () => import("../pages/content/Videos") },
        { path: "/content/video/comments", name: "VideoComments", meta: { requireAuth: true }, component: () => import("../pages/content/VideoComments") },
        { path: "/content/video/questions", name: "VideoQuestions", meta: { requireAuth: true }, component: () => import("../pages/content/VideoQuestions") },
        { path: "/content/video/feedbacks", name: "VideoFeedbacks", meta: { requireAuth: true }, component: () => import("../pages/content/VideoFeedbacks") },
        { path: "/content/firms", name: "Firms", meta: { requireAuth: true }, component: () => import("../pages/content/Firms") },
        { path: "/content/awards", name: "Awards", meta: { requireAuth: true }, component: () => import("../pages/content/Awards") },
        { path: "/content/recruit/tags", name: "RecruitTags", meta: { requireAuth: true }, component: () => import("../pages/content/RecruitTags") },
        { path: "/content/certificate/applies", name: "CertificateApplies", meta: { requireAuth: true }, component: () => import("../pages/content/CertificateApplies") },

        { path: "/management/versions", name: "AppVersions", meta: { requireAuth: true }, component: () => import("../pages/management/AppVersions") },
        { path: "/management/user/feedbacks", name: "UserFeedbacks", meta: { requireAuth: true }, component: () => import("../pages/management/UserFeedbacks") },
        { path: "/management/feedbacks", name: "Feedbacks", meta: { requireAuth: true }, component: () => import("../pages/management/Feedbacks") },
        { path: "/management/search/feedbacks", name: "SearchFeedbacks", meta: { requireAuth: true }, component: () => import("../pages/management/SearchFeedbacks") },
        { path: "/management/search/keywords", name: "SensitiveWords", meta: { requireAuth: true }, component: () => import("../pages/management/SensitiveWords") },
        { path: "/management/msg", name: "SystemMessage", meta: { requireAuth: true }, component: () => import("../pages/management/SystemMessage") },
        { path: "/management/black/accounts", name: "BlackAccounts", meta: { requireAuth: true }, component: () => import("../pages/management/BlackAccounts") },
        { path: "/management/payment/price", name: "PaymentPrice", meta: { requireAuth: true }, component: () => import("../pages/management/PaymentPrice") },

        { path: "/finance/transfer/batches", name: "TransferBatches", meta: { requireAuth: true }, component: () => import("../pages/finance/TransferBatches") },
        { path: "/finance/transfer/details", name: "TransferDetails", meta: { requireAuth: true }, component: () => import("../pages/finance/TransferDetails") },

        { path: "/teach/brands", name: "TeachBrands", meta: { requireAuth: true }, component: () => import("../pages/teach/TeachBrands") },
        { path: "/teach/pins", name: "Pins", meta: { requireAuth: true }, component: () => import("../pages/teach/Pins") },
        { path: "/teach/blueprints", name: "Blueprints", meta: { requireAuth: true }, component: () => import("../pages/teach/Blueprints") },
        { path: "/teach/failure/cases", name: "FailureCases", meta: { requireAuth: true }, component: () => import("../pages/teach/FailureCases") },
        { path: "/teach/fault/codes", name: "FaultCodes", meta: { requireAuth: true }, component: () => import("../pages/teach/FaultCodes") },
        { path: "/teach/treasures", name: "TechnicalTreasures", meta: { requireAuth: true }, component: () => import("../pages/teach/TechnicalTreasures") },
        { path: "/teach/treasure/messages", name: "TechnicalTreasureMessages", meta: { requireAuth: true }, component: () => import("../pages/teach/TechnicalTreasureMessages") },
        { path: "/teach/lessons", name: "Lessons", meta: { requireAuth: true }, component: () => import("../pages/teach/Lessons") },
        { path: "/teach/lessons/messages", name: "LessonMessages", meta: { requireAuth: true }, component: () => import("../pages/teach/LessonMessages") }
      ]
    },
  {
    path: "/login",
    name: "Login",
    meta: { requireAuth: false },
    component: () => import("../views/Login.vue")
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
