<template>
  <div id="app">
    <router-view/>
    <div class="upload-tips-div">
      <div class="review-tips-div">
        <template v-for="item in this.$store.state.reviewTips">
          <div v-show="item.reviewType === 1 && item.num > 0" class="to-be-reviewed-div">商家入驻待审核数量：<label class="reviewed-num-label">{{ item.num }}</label> 条</div>
          <div v-show="item.reviewType === 2 && item.num > 0" class="to-be-reviewed-div">服务入驻待审核数量：<label class="reviewed-num-label">{{ item.num }}</label> 条</div>
          <div v-show="item.reviewType === 3 && item.num > 0" class="to-be-reviewed-div">朋友圈待审核数量：<label class="reviewed-num-label">{{ item.num }}</label> 条</div>
          <div v-show="item.reviewType === 4 && item.num > 0" class="to-be-reviewed-div">二手整机待审核数量：<label class="reviewed-num-label">{{ item.num }}</label> 条</div>
          <div v-show="item.reviewType === 5 && item.num > 0" class="to-be-reviewed-div">二手配件待审核数量：<label class="reviewed-num-label">{{ item.num }}</label> 条</div>
          <div v-show="item.reviewType === 6 && item.num > 0" class="to-be-reviewed-div">疑难配件待审核数量：<label class="reviewed-num-label">{{ item.num }}</label> 条</div>
          <div v-show="item.reviewType === 7 && item.num > 0" class="to-be-reviewed-div">租赁公司待审核数量：<label class="reviewed-num-label">{{ item.num }}</label> 条</div>
          <div v-show="item.reviewType === 8 && item.num > 0" class="to-be-reviewed-div">招聘信息待审核数量：<label class="reviewed-num-label">{{ item.num }}</label> 条</div>
          <div v-show="item.reviewType === 9 && item.num > 0" class="to-be-reviewed-div">诚招代理待审核数量：<label class="reviewed-num-label">{{ item.num }}</label> 条</div>
          <div v-show="item.reviewType === 10 && item.num > 0" class="to-be-reviewed-div">厂家信息待审核数量：<label class="reviewed-num-label">{{ item.num }}</label> 条</div>
          <div v-show="item.reviewType === 11 && item.num > 0" class="to-be-reviewed-div">维修交流待审核数量：<label class="reviewed-num-label">{{ item.num }}</label> 条</div>
        </template>
      </div>

      <div v-for="(item, i) in this.$store.state.uploadQueue" :key="i" class="upload-item-div">
        <div class="upload-msg-div">
          <div class="clip-div"></div>
          <div class="txt-ellipsis name-div">{{ item.name }}</div>
          <div class="txt-ellipsis total-div">{{ getBeautifySize(item.total) }}</div>
          <div class="txt-ellipsis progress-div">{{ getUploadProgress(item) }}</div>
        </div>
        <div class="btn-div">
          <label v-if="item.state === -1" class="cursor-el" @click="onClear(i)">已取消</label>
          <label v-if="item.state === 0" class="cursor-el" @click="onClear(i)">上传失败</label>
          <label v-if="item.state === 3" class="cursor-el" @click="onCancel(i)">上传中</label>
          <label v-if="item.state === 1" class="cursor-el" @click="onClear(i)">已完成</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    getBeautifySize(size) {
      size = Math.floor(size / 1024 * 100) / 100;
      if(size < 1000){
        return `${size}KB`;
      }
      size = Math.floor(size / 1024 * 100) / 100;
      return size < 1000 ? `${size}MB` : `${Math.floor(size / 1024 * 100) / 100}GB`;
    },
    getUploadProgress(item) {
      return `${item.total > 0 ? Math.floor(item.loaded / item.total * 100) : 0}%`;
    },
    onClear(index) {
      this.$store.state.uploadQueue.splice(index, 1);
    },
    onCancel(index) {
      let item = this.$store.state.uploadQueue[index];
      item.source.cancel('Operation canceled by the user.')
      this.$store.state.uploadQueue.splice(index, 1);
    }
  }
}
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  background-color: #efefef;
}

button:focus,
textarea:focus,
select:focus {
  outline: none;
}

input {
  outline: none;
}

button,
input[type="button"],
input[type="submit"],
input[type="reset"],
.cursor-el {
  cursor: pointer;
}

a {
  color: #2c3e50;
  text-decoration: none;
}

td .goods-img-div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

td .goods-img-div img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

#app {
  width: 100%;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: #2c3e50;
}

.text-c {
  text-align: center;
}

.default-bg-color {
  background-color: #207ab7;
}

.success-txt {
  color: #5eb95e;
}

.red-txt {
  color: #ea3535;
}

.err-tips {
  color: red;
}

.upload-img-label {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  input {
    width: 100%;
    height: 100%;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

.upload-label {
  width: 70px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  input {
    width: 100%;
    height: 100%;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
}

.upload-tips-div {
  position: absolute;
  right: 20px;
  bottom: 20px;
  .review-tips-div {
    background-color: #e6e6e6;
    border-radius: 5px;
    .to-be-reviewed-div {
      text-align: right;
      .reviewed-num-label {
        font-weight: bold;
      }
    }
  }
  .upload-item-div {
    display: flex;
    align-items: center;
    .upload-msg-div {
      display: flex;
      align-items: center;
      position: relative;
      .clip-div {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0.3;
        background-color: #1c6ca1;
      }
      .txt-ellipsis {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .name-div {
        width: 120px;
      }
      .total-div {
        width: 130px;
        text-align: center;
        margin: 0 15px;
      }
      .progress-div {
        width: 40px;
        text-align: right;
      }
    }
    .btn-div {
      margin-left: 10px;
    }
  }
}
</style>
