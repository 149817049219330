import Vue from 'vue'
import axios from "axios";
import qs from "qs";
import store from "../store/index";
import router from "../router/index";

Vue.prototype.$baseURL = process.env.NODE_ENV === "development" ? "http://192.168.3.60/wjt" : "/wjt";
Vue.prototype.$imgBaseURL = process.env.NODE_ENV === "development" ? "http://192.168.3.60" : "";

axios.defaults.timeout = 5000; // 响应时间
axios.defaults.baseURL = Vue.prototype.$baseURL;
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8"; // 配置请求头

//POST传参序列化(添加请求拦截器)
axios.interceptors.request.use(
  config => {
    if (store.state.user.isLogin) {
      config.headers["X-Authorization"] = `Basic${store.state.user.token}`;
    }

    if (config.method === "post" && config.headers["Content-Type"] !== "multipart/form-data" && !(config.data instanceof FormData)) {
      config.data = qs.stringify(config.data, { indices: false });
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

//返回状态判断(添加响应拦截器)
axios.interceptors.response.use(
  res => {
    if (res.status === 200) {
      if (Object.prototype.hasOwnProperty.call(res, "data")) {
        if (Object.prototype.hasOwnProperty.call(res.data, "status")) {
          if (res.data.status === 1004) {
            location.reload();
            return;
          }

          if (res.data.status === 200) {
            return Promise.resolve(res.data.data);
          } else {
            return Promise.reject(res);
          }
        }
        return Promise.resolve(res.data);
      } else {
        return Promise.resolve(res);
      }
    } else {
      return Promise.reject(res);
    }
  },
  error => {
    if (error.response && error.response.status) {
      if (error.response.status) {
        switch (error.response.status) {
          case 401:
            if (store.state.initResult) {
              store.commit("setUser", { isLogin: false });
              if (router.history.current.name !== "Login") {
                router.push("/login");
              }
            } else {
              error.response.data = {status: 401, msg: "未登录登录"};
            }
            break;
          case 403:
            if (store.state.initResult) {
              store.commit("setUser", { isLogin: false });
              if (router.history.current.name !== "Login") {
                router.push("/login");
              }
            } else {
              error.response.data = {status: 403, msg: "登录过期，请重新登录"};
            }
            break;
          case 406:
            error.response.data = {status: 406, msg: "您的帐号已在其他地方登录，请重新登录"};
            store.commit("setUser", { isLogin: false });
            if (router.history.current.name !== "Login") {
              router.push("/login");
            }
            break;
          case 404:
            error.response.data = {status: 404, msg: "网络请求不存在"};
            break;
          default:
            error.response.data = {status: -1, msg: "网络异常"};
            break;
        }
        return Promise.reject(error.response);
      }

      error.data = { status: 404, msg: "网络异常" };
      return Promise.reject(error.response);
    } else {
      error.data = { status: 404, msg: "网络异常" };
      return Promise.reject(error);
    }
  }
);

//返回默认服务地址
export function getBaseURL() {
  return axios.defaults.baseURL;
}

//返回取消请求资源
export function getCancelTokenSource() {
  return axios.CancelToken.source();
}

//返回一个Promise(发送post请求)
export function doPost(url, params, config) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params, config)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

//返回一个Promise(发送get请求)
export function doGet(url, param) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params: param })
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

//返回一个Promise(上传文件)
export function doUpload(url, payload, cancelToken, callback) {
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      method: "post",
      data: payload,
      onUploadProgress: progressEvent => {
        if(progressEvent.lengthComputable){
          callback(progressEvent);
        }
      },
      cancelToken: cancelToken,
      timeout: -1
    }).then(response =>{
      resolve(response);
    }).catch(thrown => {
      reject(axios.isCancel(thrown) ? {data: {status: -1, msg: "用户取消操作"}} : thrown);
    })
  });
}

export default {
  doPost,
  doGet,
  doUpload,
  getBaseURL,
  getCancelTokenSource
};
