import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import layer from "vue-layer"
import JSEncrypt from "jsencrypt"
import init from "./common/init";
import "vue-layer/lib/vue-layer.css"
import "./common/validate"

Vue.config.productionTip = false
Vue.prototype.$layer = layer(Vue)

router.beforeEach((to, from, next) => {
  if (!store.state.initResult) {
    if (to.path === "/500") {
      next();
    } else {
      next({ path: "/500" });
    }
    return;
  }

  if (to.path === "/login") {
    if (store.state.user.isLogin) {
      next({ path: "/" });
    } else {
      next();
    }
  } else {
    if (to.meta.requireAuth) {
      if (store.state.user.isLogin) {
        next();
      } else {
        next("/login");
      }
    } else {
      next();
    }
  }
})

router.afterEach(() => {
  window.scrollTo(0, 0);
})

Vue.prototype.$validateAuthority = (first, second, third) => {
  let user = store.state.user;
  if(Object.prototype.hasOwnProperty.call(user, "authority")){
    return user.authority.some(v1 => {
      if(v1.id === first && Object.prototype.hasOwnProperty.call(v1, "children")){
        return v1.children.some(v2 => {
          if(v2.id === second && Object.prototype.hasOwnProperty.call(v2, "children")){
            return v2.children.some(v3 => {
              return v3.id === third;
            });
          }else{
            return false;
          }
        });
      }else{
        return false;
      }
    })
  }else{
    return false
  }
}

Vue.prototype.$hasAuthority = (first, second, thirds) => {
  let user = store.state.user;
  if(Object.prototype.hasOwnProperty.call(user, "authority")){
    return user.authority.some(v1 => {
      if(v1.id === first && Object.prototype.hasOwnProperty.call(v1, "children")){
        return v1.children.some(v2 => {
          if(v2.id === second && Object.prototype.hasOwnProperty.call(v2, "children")){
            return thirds.some(v => {
              return v2.children.findIndex(v3 => {
                return v3.id === v;
              }) >= 0;
            });
          }else{
            return false;
          }
        });
      }else{
        return false;
      }
    })
  }else{
    return false
  }
}

init.initData(function(publicKey) {
  if (publicKey && publicKey.length > 0) {
    Vue.prototype.$sraEncode = function(str) {
      let encrypt = new JSEncrypt();
      encrypt.setPublicKey(publicKey);
      return encrypt.encrypt(str);
    };
  }

  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app");
})
