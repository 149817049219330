import https from "./https";

export const getCancelTokenSource = () => { return https.getCancelTokenSource() };

export const verifyCodeImg = (acc, random) => { return `${https.getBaseURL()}/user-server/nk/manager/v1/validate/code?acc=${acc}&r=${random}`};
export const merchantLicenseImg = userId => { return `${https.getBaseURL()}/manage-server/yk/merchant/v1/img/license?userId=${userId}`};
export const merchantOtherImg = (userId, index) => { return `${https.getBaseURL()}/manage-server/yk/merchant/v1/img/other?userId=${userId}&index=${index}`};
export const providerLicenseImg = userId => { return `${https.getBaseURL()}/manage-server/yk/provider/v1/img/license?userId=${userId}`};
export const leaseLicenseImg = leaseId => { return `${https.getBaseURL()}/manage-server/yk/lease/v1/img/license?id=${leaseId}`};
export const certificateImg = (infoId, type) => { return `${https.getBaseURL()}/manage-server/yk/user/v1/certificate/img?id=${infoId}&type=${type}`};

export const getPublicKey = () => { return https.doGet("user-server/nk/manager/v1/key") };
export const getValidateAcc = params => { return https.doGet("user-server/nk/manager/v1/validate/acc", params) };
export const postLogin = params => { return https.doPost("user-server/nk/manager/v1/login", params) };
export const postLogout = () => { return https.doPost("user-server/yk/manager/v1/logout") };
export const postEditOwnPwd = params => { return https.doPost("user-server/yk/manager/v1/edit/pwd", params) };

export const getProvinces = () => { return https.doGet("manage-server/nk/prefecture/v1/province") };
export const getCities = params => { return https.doGet("manage-server/nk/prefecture/v1/city", params) };
export const getCounties = params => { return https.doGet("manage-server/nk/prefecture/v1/county", params) };

export const getAuthority = () => { return https.doGet("manage-server/yk/authority/v1/list") };
export const postResetAuthority = () => { return https.doPost("manage-server/yk/authority/v1/reset/cache") };

export const getGroupItems = params => { return https.doGet("manage-server/yk/group/v1/items", params) };
export const getGroupNameItems = params => { return https.doGet("manage-server/yk/group/v1/name/items", params) };
export const getGroupAuthority = params => { return https.doGet("manage-server/yk/group/v1/authority", params) };
export const postAddGroup = params => { return https.doPost("manage-server/yk/group/v1/add", params) };
export const postDelGroup = params => { return https.doPost("manage-server/yk/group/v1/del", params) };
export const getGroupAuthorityState = params => { return https.doGet("manage-server/yk/group/v1/authority/state", params) };
export const postEditGroup = params => { return https.doPost("manage-server/yk/group/v1/authority/edit", params) };

export const getManagerItems = params => { return https.doGet("manage-server/yk/manager/v1/items", params) };
export const postManagerAdd = params => { return https.doPost("manage-server/yk/manager/v1/add", params) };
export const postEditManagerState = params => { return https.doPost("manage-server/yk/manager/v1/edit/state", params) };
export const postEditManagerPwd = params => { return https.doPost("manage-server/yk/manager/v1/edit/pwd", params) };
export const postEditManagerInfo = params => { return https.doPost("manage-server/yk/manager/v1/edit/info", params) };
export const postEditFirmManagerInfo = params => { return https.doPost("manage-server/yk/manager/v1/firm/edit/info", params) };
export const getReviewNums = () => { return https.doGet("manage-server/yk/manager/v1/review/nums") };

export const getUserItems = params => { return https.doGet("manage-server/yk/user/v1/items", params) };
export const getUserDetails = params => { return https.doGet("manage-server/yk/user/v1/details", params) };
export const postUserDel = params => { return https.doPost("manage-server/yk/user/v1/del", params) };
export const postUserVipAdd = params => { return https.doPost("manage-server/yk/user/v1/vip/add", params) };

export const getPartnerItems = params => { return https.doGet("manage-server/yk/user/v1/partner/items", params) };
export const getUserFinanceItems = params => { return https.doGet("manage-server/yk/user/v1/finance/items", params) };

export const getCertificateTypes = params => { return https.doGet("manage-server/yk/user/v1/certificate/types", params) };
export const postAddCertificateType = params => { return https.doPost("manage-server/yk/user/v1/certificate/type/add", params) };
export const postDelCertificateType = params => { return https.doPost("manage-server/yk/user/v1/certificate/type/del", params) };
export const postEditCertificateType = params => { return https.doPost("manage-server/yk/user/v1/certificate/type/edit", params) };

export const getApplyCertificateItems = params => { return https.doGet("manage-server/yk/user/v1/apply/certificate/items", params) };
export const getApplyCertificateInfo = params => { return https.doGet("manage-server/yk/user/v1/apply/certificate/info", params) };
export const postApplyCertificateDel = params => { return https.doPost("manage-server/yk/user/v1/apply/certificate/del", params) };

export const getMasterAdd = params => { return https.doPost("manage-server/yk/master/v1/add", params) };
export const getMasterEdit = params => { return https.doPost("manage-server/yk/master/v1/edit", params) };
export const getMasterDel = params => { return https.doPost("manage-server/yk/master/v1/del", params) };
export const getMasterItems = params => { return https.doGet("manage-server/yk/master/v1/items", params) };
export const getMasterInfo = params => { return https.doGet("manage-server/yk/master/v1/info", params) };

export const getInsurerAdd = params => { return https.doPost("manage-server/yk/insurer/v1/add", params) };
export const getInsurerEdit = params => { return https.doPost("manage-server/yk/insurer/v1/edit", params) };
export const getInsurerDel = params => { return https.doPost("manage-server/yk/insurer/v1/del", params) };
export const getInsurerItems = params => { return https.doGet("manage-server/yk/insurer/v1/items", params) };
export const getInsurerInfo = params => { return https.doGet("manage-server/yk/insurer/v1/info", params) };

export const postRecruitTagAdd = params => { return https.doPost("manage-server/yk/recruit/v1/tag/add", params) };
export const postRecruitTagEdit = params => { return https.doPost("manage-server/yk/recruit/v1/tag/edit", params) };
export const getRecruitTagItems = params => { return https.doGet("manage-server/yk/recruit/v1/tag/items", params) };
export const getRecruitTags3 = params => { return https.doGet("manage-server/yk/recruit/v1/tags/3", params) };

export const getMerchantItems = params => { return https.doGet("manage-server/yk/merchant/v1/items", params) };
export const getMerchantDetails = params => { return https.doGet("manage-server/yk/merchant/v1/details", params) };
export const postMerchantPass = params => { return https.doPost("manage-server/yk/merchant/v1/pass", params) };
export const postMerchantReject = params => { return https.doPost("manage-server/yk/merchant/v1/reject", params) };

export const getProviderItems = params => { return https.doGet("manage-server/yk/provider/v1/items", params) };
export const getProviderDetails = params => { return https.doGet("manage-server/yk/provider/v1/details", params) };
export const postProviderPass = params => { return https.doPost("manage-server/yk/provider/v1/pass", params) };
export const postProviderReject = params => { return https.doPost("manage-server/yk/provider/v1/reject", params) };

export const getBannerItems = params => { return https.doGet("manage-server/yk/banner/v1/items", params) };
export const getBannerDetails = params => { return https.doGet("manage-server/yk/banner/v1/details", params) };
export const postAddBanner = params => { return https.doPost("manage-server/yk/banner/v1/add", params) };
export const postEditBanner = params => { return https.doPost("manage-server/yk/banner/v1/edit/info", params) };
export const postEditBannerState = params => { return https.doPost("manage-server/yk/banner/v1/edit/state", params) };
export const postDelBanner = params => { return https.doPost("manage-server/yk/banner/v1/del", params) };

export const getImgItems = params => { return https.doGet("manage-server/yk/gallery/v1/items", params) };
export const postImgAdd = params => { return https.doPost("manage-server/yk/gallery/v1/add", params) };
export const postImgDel = params => { return https.doPost("manage-server/yk/gallery/v1/del", params) };

export const getNewsItems = params => { return https.doGet("manage-server/yk/news/v1/items", params) };
export const getNewsDetails = params => { return https.doGet("manage-server/yk/news/v1/details", params) };
export const postNewsAdd = params => { return https.doPost("manage-server/yk/news/v1/add", params) };
export const postNewsDel = params => { return https.doPost("manage-server/yk/news/v1/del", params) };
export const postEditNews = params => { return https.doPost("manage-server/yk/news/v1/edit", params) };
export const postEditNewsState = params => { return https.doPost("manage-server/yk/news/v1/edit/state", params) };

export const getNewsCommentItems = params => { return https.doGet("manage-server/yk/news/v1/comment/items", params) };
export const postNewsCommentDel = params => { return https.doPost("manage-server/yk/news/v1/comment/del", params) };
export const postNewsCommentReply = params => { return https.doPost("manage-server/yk/news/v1/comment/reply", params) };

export const postBrandAdd = params => { return https.doPost("manage-server/yk/brand/v1/add", params) };
export const postBrandEdit = params => { return https.doPost("manage-server/yk/brand/v1/edit", params) };
export const getBrandItems = params => { return https.doGet("manage-server/yk/brand/v1/items", params) };
export const getBrandNameItems = params => { return https.doGet("manage-server/yk/brand/v1/name/page", params) };

export const getBrandCommentItems = params => { return https.doGet("manage-server/yk/brand/v1/comment/items", params) };
export const postBrandCommentDel = params => { return https.doPost("manage-server/yk/brand/v1/comment/del", params) };
export const postBrandCommentReply = params => { return https.doPost("manage-server/yk/brand/v1/comment/reply", params) };

export const getBrandPartsCommentItems = params => { return https.doGet("manage-server/yk/bp/v1/comment/items", params) };
export const postBrandPartsCommentDel = params => { return https.doPost("manage-server/yk/bp/v1/comment/del", params) };
export const postBrandPartsCommentReply = params => { return https.doPost("manage-server/yk/bp/v1/comment/reply", params) };

export const getMachineryPartsItems = params => { return https.doGet("manage-server/yk/machinery/v1/parts/base/items", params) };
export const postMachineryPartsEdit = params => { return https.doPost("manage-server/yk/machinery/v1/parts/base/edit", params) };
export const postMachineryPartsAdd = params => { return https.doPost("manage-server/yk/machinery/v1/parts/base/add", params) };
export const getMachineryItems = params => { return https.doGet("manage-server/yk/machinery/v1/items", params) };
export const getMachineryPartsMsgItems = params => { return https.doGet("manage-server/yk/machinery/v1/parts/base/msg/items", params) };
export const postMachineryAdd = params => { return https.doPost("manage-server/yk/machinery/v1/add", params) };
export const getMachineryDetails = params => { return https.doGet("manage-server/yk/machinery/v1/details", params) };
export const postMachineryEdit = params => { return https.doPost("manage-server/yk/machinery/v1/edit", params) };
export const postMachineryStateEdit = params => { return https.doPost("manage-server/yk/machinery/v1/edit/state", params) };

export const getBrandPartsGroupItems = params => { return https.doGet("manage-server/yk/bp/v1/group/items", params) };
export const getBrandPartsGroupNameItems = params => { return https.doGet("manage-server/yk/bp/v1/group/name/items", params) };
export const postBrandPartsGroupAdd = params => { return https.doPost("manage-server/yk/bp/v1/group/add", params) };
export const postBrandPartsGroupEdit = params => { return https.doPost("manage-server/yk/bp/v1/group/edit", params) };
export const postBrandPartsGroupDel = params => { return https.doPost("manage-server/yk/bp/v1/group/del", params) };
export const getBrandPartsTypeItems = params => { return https.doGet("manage-server/yk/bp/v1/type/items", params) };
export const getBrandPartsTypeNameItems = params => { return https.doGet("manage-server/yk/bp/v1/type/name/items", params) };
export const postBrandPartsTypeAdd = params => { return https.doPost("manage-server/yk/bp/v1/type/add", params) };
export const postBrandPartsTypeEdit = params => { return https.doPost("manage-server/yk/bp/v1/type/edit", params) };
export const postBrandPartsTypeDel = params => { return https.doPost("manage-server/yk/bp/v1/type/del", params) };
export const getBrandPartsItems = params => { return https.doGet("manage-server/yk/bp/v1/items", params) };
export const getBrandPartsDetails = params => { return https.doGet("manage-server/yk/bp/v1/details", params) };
export const postBrandPartsAdd = params => { return https.doPost("manage-server/yk/bp/v1/add", params) };
export const postBrandPartsEdit = params => { return https.doPost("manage-server/yk/bp/v1/edit", params) };
export const postBrandPartsDel = params => { return https.doPost("manage-server/yk/bp/v1/del", params) };
export const getBrandPartsModelItems = params => { return https.doGet("manage-server/yk/bp/v1/model/items", params) };
export const postBrandPartsModelDel = params => { return https.doPost("manage-server/yk/bp/v1/model/del", params) };
export const postBrandPartsModelAdd = params => { return https.doPost("manage-server/yk/bp/v1/model/add", params) };
export const getBrandPartsModelDetails = params => { return https.doGet("manage-server/yk/bp/v1/model/details", params) };
export const postBrandPartsModelEdit = params => { return https.doPost("manage-server/yk/bp/v1/model/edit", params) };

export const getDynamicItems = params => { return https.doGet("manage-server/yk/dynamic/v1/items", params) };
export const getDynamicDetails = params => { return https.doGet("manage-server/yk/dynamic/v1/details", params) };
export const postDynamicPass = params => { return https.doPost("manage-server/yk/dynamic/v1/pass", params) };
export const postDynamicReject = params => { return https.doPost("manage-server/yk/dynamic/v1/reject", params) };

export const getCommunicationItems = params => { return https.doGet("manage-server/yk/communication/v1/items", params) };
export const getCommunicationDetails = params => { return https.doGet("manage-server/yk/communication/v1/details", params) };
export const postCommunicationPass = params => { return https.doPost("manage-server/yk/communication/v1/pass", params) };
export const postCommunicationReject = params => { return https.doPost("manage-server/yk/communication/v1/reject", params) };

export const getMachineItems = params => { return https.doGet("manage-server/yk/machine/v1/items", params) };
export const getMachineDetails = params => { return https.doGet("manage-server/yk/machine/v1/details", params) };
export const postMachinePass = params => { return https.doPost("manage-server/yk/machine/v1/pass", params) };
export const postMachineReject = params => { return https.doPost("manage-server/yk/machine/v1/reject", params) };

export const getPartsItems = params => { return https.doGet("manage-server/yk/parts/v1/items", params) };
export const getPartsDetails = params => { return https.doGet("manage-server/yk/parts/v1/details", params) };
export const postPartsPass = params => { return https.doPost("manage-server/yk/parts/v1/pass", params) };
export const postPartsReject = params => { return https.doPost("manage-server/yk/parts/v1/reject", params) };

export const getAccessoryItems = params => { return https.doGet("manage-server/yk/accessory/v1/items", params) };
export const getAccessoryDetails = params => { return https.doGet("manage-server/yk/accessory/v1/details", params) };
export const postAccessoryPass = params => { return https.doPost("manage-server/yk/accessory/v1/pass", params) };
export const postAccessoryReject = params => { return https.doPost("manage-server/yk/accessory/v1/reject", params) };

export const getLeaseItems = params => { return https.doGet("manage-server/yk/lease/v1/items", params) };
export const getLeaseDetails = params => { return https.doGet("manage-server/yk/lease/v1/details", params) };
export const postLeasePass = params => { return https.doPost("manage-server/yk/lease/v1/pass", params) };
export const postLeaseReject = params => { return https.doPost("manage-server/yk/lease/v1/reject", params) };

export const getRecruitItems = params => { return https.doGet("manage-server/yk/recruit/v1/items", params) };
export const getRecruitDetails = params => { return https.doGet("manage-server/yk/recruit/v1/details", params) };
export const postRecruitPass = params => { return https.doPost("manage-server/yk/recruit/v1/pass", params) };
export const postRecruitReject = params => { return https.doPost("manage-server/yk/recruit/v1/reject", params) };

export const getOldPartsItems = params => { return https.doGet("manage-server/yk/old/v1/items", params) };
export const getOldPartsDetails = params => { return https.doGet("manage-server/yk/old/v1/details", params) };
export const postOldPartsDel = params => { return https.doPost("manage-server/yk/old/v1/del", params) };

export const getAgencyItems = params => { return https.doGet("manage-server/yk/agency/v1/items", params) };
export const getAgencyDetails = params => { return https.doGet("manage-server/yk/agency/v1/details", params) };
export const postAgencyPass = params => { return https.doPost("manage-server/yk/agency/v1/pass", params) };
export const postAgencyReject = params => { return https.doPost("manage-server/yk/agency/v1/reject", params) };

export const getManufacturerItems = params => { return https.doGet("manage-server/yk/manufacturer/v1/items", params) };
export const getManufacturerDetails = params => { return https.doGet("manage-server/yk/manufacturer/v1/details", params) };
export const postManufacturerPass = params => { return https.doPost("manage-server/yk/manufacturer/v1/pass", params) };
export const postManufacturerReject = params => { return https.doPost("manage-server/yk/manufacturer/v1/reject", params) };
export const postManufacturerDel = params => { return https.doPost("manage-server/yk/manufacturer/v1/del", params) };
export const postManufacturerTop = params => { return https.doPost("manage-server/yk/manufacturer/v1/top", params) };

export const getManufacturerTypeItems = params => { return https.doGet("manage-server/yk/manufacturer/v1/type/items", params) };
export const postManufacturerTypeAdd = params => { return https.doPost("manage-server/yk/manufacturer/v1/type/add", params) };
export const postManufacturerTypeEdit = params => { return https.doPost("manage-server/yk/manufacturer/v1/type/edit", params) };

export const getManufacturerDiagramItems = params => { return https.doGet("manage-server/yk/diagram/v1/info/items", params) };
export const getManufacturerTypes = () => { return https.doGet("manage-server/yk/diagram/v1/types") };
export const postManufacturerDiagramAdd = params => { return https.doPost("manage-server/yk/diagram/v1/info/add", params) };
export const postManufacturerDiagramEdit = params => { return https.doPost("manage-server/yk/diagram/v1/info/edit", params) };

export const getManufacturerAtlasItems = params => { return https.doGet("manage-server/yk/diagram/v1/type/items", params) };
export const getManufacturerUsedTypes = params => { return https.doGet("manage-server/yk/diagram/v1/used/types", params) };
export const postManufacturerAtlasAdd = params => { return https.doPost("manage-server/yk/diagram/v1/type/add", params) };
export const getManufacturerAtlasDetails = params => { return https.doGet("manage-server/yk/diagram/v1/atlas/details", params) };
export const postManufacturerAtlasEdit = params => { return https.doPost("manage-server/yk/diagram/v1/type/edit", params) };
export const postManufacturerAtlasImgAdd = params => { return https.doPost("manage-server/yk/diagram/v1/atlas/add", params) };
export const postManufacturerAtlasImgEdit = params => { return https.doPost("manage-server/yk/diagram/v1/atlas/edit", params) };
export const postManufacturerAtlasImgDel = params => { return https.doPost("manage-server/yk/diagram/v1/atlas/del", params) };

export const getAgentItems = params => { return https.doGet("manage-server/yk/agent/v1/items", params) };
export const postAgentAdd = params => { return https.doPost("manage-server/yk/agent/v1/add", params) };
export const postAgentDel = params => { return https.doPost("manage-server/yk/agent/v1/del", params) };
export const getAgentDetails = params => { return https.doGet("manage-server/yk/agent/v1/details", params) };
export const postAgentEdit = params => { return https.doPost("manage-server/yk/agent/v1/edit", params) };
export const postAgentPointAdd = params => { return https.doPost("manage-server/yk/agent/v1/point/add", params) };
export const postAgentPointEdit = params => { return https.doPost("manage-server/yk/agent/v1/point/edit", params) };
export const postAgentPointDel = params => { return https.doPost("manage-server/yk/agent/v1/point/del", params) };

export const getAgentCommentItems = params => { return https.doGet("manage-server/yk/agent/v1/comment/items", params) };
export const postAgentCommentDel = params => { return https.doPost("manage-server/yk/agent/v1/comment/del", params) };
export const postAgentCommentReply = params => { return https.doPost("manage-server/yk/agent/v1/comment/reply", params) };

export const getLecturerNameItems = params => { return https.doGet("manage-server/yk/lecturer/v1/name/items", params) };
export const getLecturerItems = params => { return https.doGet("manage-server/yk/lecturer/v1/items", params) };
export const getLecturerDetails = params => { return https.doGet("manage-server/yk/lecturer/v1/details", params) };
export const postLecturerDel = params => { return https.doPost("manage-server/yk/lecturer/v1/del", params) };
export const postLecturerAdd = params => { return https.doPost("manage-server/yk/lecturer/v1/add", params) };
export const postLecturerEdit = params => { return https.doPost("manage-server/yk/lecturer/v1/edit", params) };

export const getLecturerVideoItems = params => { return https.doGet("manage-server/yk/lecturer/v1/video/items", params) };
export const postLecturerVideoState = params => { return https.doPost("manage-server/yk/lecturer/v1/video/edit/state", params) };
export const postLecturerVideoDel = params => { return https.doPost("manage-server/yk/lecturer/v1/video/del", params) };
export const postLecturerVideoAdd = (params, cancelToken, callback) => { return https.doUpload("manage-server/yk/lecturer/v1/video/add", params, cancelToken, callback) };
export const postLecturerVideoEdit = params => { return https.doPost("manage-server/yk/lecturer/v1/video/edit", params) };
export const getLecturerVideoInfo = params => { return https.doGet("manage-server/yk/lecturer/v1/video/info", params) };

export const getLecturerCommentItems = params => { return https.doGet("manage-server/yk/lecturer/v1/comment/items", params) };
export const postLecturerCommentDel = params => { return https.doPost("manage-server/yk/lecturer/v1/comment/del", params) };
export const postLecturerCommentReply = params => { return https.doPost("manage-server/yk/lecturer/v1/comment/reply", params) };

export const getLecturerVideoCommentItems = params => { return https.doGet("manage-server/yk/lecturer/v1/video/comment/items", params) };
export const postLecturerVideoCommentDel = params => { return https.doPost("manage-server/yk/lecturer/v1/video/comment/del", params) };
export const postLecturerVideoCommentReply = params => { return https.doPost("manage-server/yk/lecturer/v1/video/comment/reply", params) };

export const getLecturerVideoQuestionItems = params => { return https.doGet("manage-server/yk/lecturer/v1/video/question/items", params) };
export const postLecturerVideoQuestionDel = params => { return https.doPost("manage-server/yk/lecturer/v1/video/question/del", params) };
export const postLecturerVideoQuestionReply = params => { return https.doPost("manage-server/yk/lecturer/v1/video/question/reply", params) };

export const getLecturerVideoFeedbackItems = params => { return https.doGet("manage-server/yk/lecturer/v1/video/feedback/items", params) };
export const postLecturerVideoFeedbackDel = params => { return https.doPost("manage-server/yk/lecturer/v1/video/feedback/del", params) };

export const getFirmItems = params => { return https.doGet("manage-server/yk/firm/v1/items", params) };
export const postFirmAdd = params => { return https.doPost("manage-server/yk/firm/v1/add", params) };
export const postFirmStateEdit = params => { return https.doPost("manage-server/yk/firm/v1/edit/state", params) };
export const postFirmDel = params => { return https.doPost("manage-server/yk/firm/v1/del", params) };
export const getFirmDetails = params => { return https.doGet("manage-server/yk/firm/v1/details", params) };
export const postFirmEdit = params => { return https.doPost("manage-server/yk/firm/v1/edit", params) };
export const getFirmNameItems = params => { return https.doGet("manage-server/yk/firm/v1/name/items", params) };
export const getFirmMemberTypeItems = params => { return https.doGet("manage-server/yk/firm/v1/member/type/items", params) };
export const postFirmMemberTypeAdd = params => { return https.doPost("manage-server/yk/firm/v1/member/type/add", params) };
export const postFirmMemberTypeEdit = params => { return https.doPost("manage-server/yk/firm/v1/member/type/edit", params) };

export const postVersionAdd = (params, cancelToken, callback) => { return https.doUpload("manage-server/yk/version/v1/add", params, cancelToken, callback) };
export const postVersionEdit = params => { return https.doPost("manage-server/yk/version/v1/edit", params) };
export const postVersionDel = params => { return https.doPost("manage-server/yk/version/v1/del", params) };
export const getVersionInfo = params => { return https.doGet("manage-server/yk/version/v1/info", params) };
export const getVersionItems = params => { return https.doGet("manage-server/yk/version/v1/items", params) };

export const getUserFeedbackItems = params => { return https.doGet("manage-server/yk/feedback/v1/user/items", params) };
export const getUserFeedbackDetails = params => { return https.doGet("manage-server/yk/feedback/v1/user/details", params) };
export const getFeedbackItems = params => { return https.doGet("manage-server/yk/feedback/v1/items", params) };
export const getFeedbackDetails = params => { return https.doGet("manage-server/yk/feedback/v1/details", params) };
export const getSearchFeedbackItems = params => { return https.doGet("manage-server/yk/feedback/v1/search/items", params) };

export const getSensitiveWordsItems = params => { return https.doGet("manage-server/yk/sensitive/v1/items", params) };
export const postSensitiveWordsAdd = params => { return https.doPost("manage-server/yk/sensitive/v1/add", params) };
export const postSensitiveWordsDel = params => { return https.doPost("manage-server/yk/sensitive/v1/del", params) };
export const postSensitiveWordsEdit = params => { return https.doPost("manage-server/yk/sensitive/v1/edit", params) };

export const getBlackAccItems = params => { return https.doGet("manage-server/yk/user/v1/black/items", params) };
export const postBlackAccAdd = params => { return https.doPost("manage-server/yk/user/v1/black/add", params) };
export const postBlackAccDel = params => { return https.doPost("manage-server/yk/user/v1/black/del", params) };

export const postSystemMsgAdd = params => { return https.doPost("manage-server/yk/system/v1/msg/add", params) };

export const getTransferBatchItems = params => { return https.doGet("manage-server/yk/score/v1/transfer/batch/items", params) };

export const getTransferDetailItems = params => { return https.doGet("manage-server/yk/score/v1/transfer/detail/items", params) };
export const postTransferAgree = params => { return https.doPost("manage-server/yk/score/v1/transfer/agree", params) };
export const postTransferRefuse = params => { return https.doPost("manage-server/yk/score/v1/transfer/refuse", params) };

export const getUserAwardItems = params => { return https.doGet("manage-server/yk/award/v1/notes/items", params) };
export const getAwardItems = params => { return https.doGet("manage-server/yk/award/v1/items", params) };
export const postAwardEdit = params => { return https.doPost("manage-server/yk/award/v1/edit", params) };
export const postAwardDeliver = params => { return https.doPost("manage-server/yk/award/v1/deliver", params) };

export const postTeachBrandAdd = params => { return https.doPost("manage-server/yk/tb/v1/add", params) };
export const postTeachBrandEdit = params => { return https.doPost("manage-server/yk/tb/v1/edit", params) };
export const getTeachBrandItems = params => { return https.doGet("manage-server/yk/tb/v1/items", params) };
export const getTeachBrandNameItems = params => { return https.doGet("manage-server/yk/tb/v1/name/page", params) };

export const postBlueprintAdd = (params, cancelToken, callback) => { return https.doUpload("manage-server/yk/blueprint/v1/add", params, cancelToken, callback) };
export const postBlueprintEdit = params => { return https.doPost("manage-server/yk/blueprint/v1/edit", params) };
export const postBlueprintDel = params => { return https.doPost("manage-server/yk/blueprint/v1/del", params) };
export const getBlueprintItems = params => { return https.doGet("manage-server/yk/blueprint/v1/items", params) };

export const postBlueprintTypeAdd = params => { return https.doPost("manage-server/yk/blueprint/v1/type/add", params) };
export const getBlueprintTypeItems = params => { return https.doGet("manage-server/yk/blueprint/v1/type/items", params) };
export const getBlueprintType = () => { return https.doGet("manage-server/yk/blueprint/v1/types") };

export const postFailureCaseAdd = (params, cancelToken, callback) => { return https.doUpload("manage-server/yk/fc/v1/add", params, cancelToken, callback) };
export const postFailureCaseEdit = params => { return https.doPost("manage-server/yk/fc/v1/edit", params) };
export const postFailureCaseDel = params => { return https.doPost("manage-server/yk/fc/v1/del", params) };
export const getFailureCaseItems = params => { return https.doGet("manage-server/yk/fc/v1/items", params) };

export const postTopicAdd = params => { return https.doPost("manage-server/yk/means/v1/topic/add", params) };
export const postTopicEdit = params => { return https.doPost("manage-server/yk/means/v1/topic/edit", params) };
export const postTopicDel = params => { return https.doPost("manage-server/yk/means/v1/topic/del", params) };
export const getTopicItems = params => { return https.doGet("manage-server/yk/means/v1/topic/items", params) };
export const postTreasureAdd = (params, cancelToken, callback) => { return https.doUpload("manage-server/yk/means/v1/treasure/add", params, cancelToken, callback) };
export const postTreasureEdit = params => { return https.doPost("manage-server/yk/means/v1/treasure/edit", params) };
export const postTreasureDel = params => { return https.doPost("manage-server/yk/means/v1/treasure/del", params) };
export const getTreasureItems = params => { return https.doGet("manage-server/yk/means/v1/treasure/items", params) };

export const postLessonAdd = params => { return https.doPost("manage-server/yk/lesson/v1/add", params) };
export const postLessonEdit = params => { return https.doPost("manage-server/yk/lesson/v1/edit", params) };
export const postLessonDel = params => { return https.doPost("manage-server/yk/lesson/v1/del", params) };
export const getLessonItems = params => { return https.doGet("manage-server/yk/lesson/v1/items", params) };
export const getLessonInfo = params => { return https.doGet("manage-server/yk/lesson/v1/info", params) };
export const postLessonMsgAdd = (params, cancelToken, callback) => { return https.doUpload("manage-server/yk/lesson/v1/msg/add", params, cancelToken, callback) };
export const postLessonMsgEdit = params => { return https.doPost("manage-server/yk/lesson/v1/msg/edit", params) };
export const postLessonMsgDel = params => { return https.doPost("manage-server/yk/lesson/v1/msg/del", params) };
export const postLessonMsgState = params => { return https.doPost("manage-server/yk/lesson/v1/msg/edit/state", params) };
export const getLessonMsgItems = params => { return https.doGet("manage-server/yk/lesson/v1/msg/items", params) };

export const postPinAdd = (params, cancelToken, callback) => { return https.doUpload("manage-server/yk/pin/v1/add", params, cancelToken, callback) };
export const postPinEdit = params => { return https.doPost("manage-server/yk/pin/v1/edit", params) };
export const postPinDel = params => { return https.doPost("manage-server/yk/pin/v1/del", params) };
export const getPinItems = params => { return https.doGet("manage-server/yk/pin/v1/items", params) };

export const postFaultCodeAdd = (params, cancelToken, callback) => { return https.doUpload("manage-server/yk/fault/v1/add", params, cancelToken, callback) };
export const postFaultCodeAdd2 = params => { return https.doPost("manage-server/yk/fault/v1/add", params) };
export const postFaultCodeEdit = params => { return https.doPost("manage-server/yk/fault/v1/edit", params) };
export const postFaultCodeDel = params => { return https.doPost("manage-server/yk/fault/v1/del", params) };
export const postFaultCodeState = params => { return https.doPost("manage-server/yk/fault/v1/edit/state", params) };
export const getFaultCodeInfo = params => { return https.doGet("manage-server/yk/fault/v1/info", params) };
export const getFaultCodeItems = params => { return https.doGet("manage-server/yk/fault/v1/items", params) };

export const getPriceItems = () => { return https.doGet("manage-server/yk/price/v1/items") };
export const postPriceEdit = params => { return https.doPost("manage-server/yk/price/v1/edit", params) };