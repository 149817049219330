<template>
  <div class="page-div">
    <div class="page-top-div">
      <div class="left-div">
        <div class="system-name-div">系统管理</div>
        <audio ref="mp3" controls="controls" src="../../public/audio/tips.mp3" hidden />
      </div>
      <div class="right-div">
        <ul class="menu-nav">
          <li class="menu-li">
            <label class="menu-name-label cursor-el">{{ this.$store.state.user.name }}</label>
            <ul class="nav">
<!--              <li><label class="cursor-el" @click="onShowEditSelfMsg">个人信息</label></li>-->
              <li><label class="cursor-el" @click="onShowEditSelfPwd">修改密码</label></li>
            </ul>
          </li>
          <li class="menu-li">
            <label class="menu-name-label cursor-el" @click="onLogout">安全退出</label>
          </li>
        </ul>
      </div>
    </div>
    <div class="page-middle-div">
      <div class="page-menu-div" :class="{'page-menu-div-open':isShowMenuDiv, 'page-menu-div-hidden':!isShowMenuDiv}">
        <template v-for="item in this.$store.state.user.authority">
          <div class="tree-menu-div tree-menu-first-div cursor-el" :key="item.id" @click="onShowSecondMenu(item.id)">
            <div class="menu-img-div">
              <img :src="require(`../assets/${item.iconName}.png`)" alt="" />
            </div>
            <div>{{ item.name }}</div>
          </div>

          <template v-for="child in item.children">
            <div
                :class="{
                  'tree-menu-div': true,
                  'tree-menu-second-div': true,
                  'cursor-el': true,
                  'selected-second-menu-div': selectedSecondMenuId === child.id
                }"
                v-show="selectedFirstMenuId === item.id"
                :key="child.id"
                @click="onShowRightPage(child.id, child.pageName)">
              <label class="menu-txt-div cursor-el">{{ child.name }}</label>
            </div>
          </template>
        </template>
      </div>
      <div class="child-page-content-div">
        <label class="display-arrow-label" :class="isShowMenuDiv ? 'hidden' : 'open'" @click="onShowMenu" />
        <router-view class="child-page-view" @handleToggle="togglePage" />
      </div>
    </div>
  </div>
</template>

<script>
import { getReviewNums, postLogout } from "../common/api";
// import PersonalMsgEditLayer from "../pages/layer/PersonalMsgEditLayer";
import PersonalPwdEditLayer from "../pages/layer/PersonalPwdEditLayer";

export default {
  name: "Home",
  data() {
    return {
      isShowMenuDiv: true,
      selectedFirstMenuId: 0,
      selectedSecondMenuId: 0
    };
  },
  created() {
    let routeName = this.$router.currentRoute.name;
    this.$store.state.user.authority.some(item => {
      if (Object.prototype.hasOwnProperty.call(item, "children")) {
        let isSelectedMenu = false;
        item.children.some(child => {
          if (child.pageName === routeName) {
            this.selectedFirstMenuId = item.id;
            this.selectedSecondMenuId = child.id;
            isSelectedMenu = true;
            return true;
          }
        });
        if (isSelectedMenu) {
          return true;
        }
      }
    });
    this.getToBeReviewed();
  },
  beforeDestroy() {
    clearTimeout(this.reviewedTimeCounter);
  },
  methods: {
    playTipsMusic() {
      this.$refs.mp3.play();
    },
    getToBeReviewed() {
      if(this.$store.state.user.isLogin){
        getReviewNums().then(data => {
          let type = Object.prototype.toString.call(data);
          if(type === "[object Array]"){
            this.$store.state.reviewTips = [];
            let isNeedPlay = false;
            data.forEach(e => {
              isNeedPlay = (Object.prototype.hasOwnProperty.call(e, "num") && e.num > 0) || isNeedPlay;
              this.$store.state.reviewTips.push(e);
            })

            if(isNeedPlay){
              this.playTipsMusic();
            }
          }
          this.reviewedTimer();
        }).catch(error => {
          this.reviewedTimer();
        });
      }else{
        this.$store.state.reviewTips = [];
      }
    },
    reviewedTimer() {
      clearTimeout(this.reviewedTimeCounter);
      if(this.$store.state.user.isLogin){
        this.reviewedTimeCounter = setTimeout(() => {
          clearTimeout(this.reviewedTimeCounter);
          this.getToBeReviewed();
        }, 15000);
      }else{
        this.$store.state.reviewTips = [];
      }
    },
    onShowMenu() {
      this.isShowMenuDiv = !this.isShowMenuDiv;
    },
    onShowSecondMenu(menuId) {
      this.selectedFirstMenuId = menuId === this.selectedFirstMenuId ? -1 : menuId;
      this.selectedSecondMenuId = 0;
    },
    onShowRightPage(menuId, pageName) {
      this.selectedSecondMenuId = menuId;
      if (pageName !== this.$route.name) {
        this.$router.push({ name: pageName });
      }
    },
    togglePage(firstMenuId, secondMenuId, pageName) {
      this.isShowMenuDiv = true;
      this.selectedFirstMenuId = firstMenuId;
      this.selectedSecondMenuId = secondMenuId;
      if (pageName !== this.$route.name) {
        this.$router.push({ name: pageName });
      }
    },
    onShowEditSelfMsg() {
      this.$layer.iframe({
        content: {
          content: PersonalMsgEditLayer,
          parent: this,
          data: {
            name: this.$store.state.user.name,
            phone: this.$store.state.user.phone
          }
        },
        area: ["360px", "380px"],
        title: "编辑个人信息"
      });
    },
    onShowEditSelfPwd() {
      this.$layer.iframe({
        content: {
          content: PersonalPwdEditLayer,
          parent: this
        },
        area: ["580px", "380px"],
        title: "修改密码信息"
      });
    },
    onLogout() {
      postLogout()
          .then(() => {
            this.$store.commit("setUser", { isLogin: false });
            this.$router.push({ name: "Login" });
          })
          .catch(() => {
            this.$store.commit("setUser", { isLogin: false });
            this.$router.push({ name: "Login" });
          });
    }
  }
};
</script>

<style lang="less" scoped>
.page-div {
  width: 100%;
  height: 100%;
  .page-top-div {
    height: 44px;
    background: #4676c8;
    position: relative;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    .left-div {
      display: flex;
      .system-name-div {
        padding: 0 20px;
        font-weight: bold;
        color: white;
        font-size: 26px;
        letter-spacing: 5px;
        line-height: 44px;
      }
    }
    .right-div {
      margin-right: 10px;
      li {
        list-style: none;
      }
      .menu-nav {
        height: 44px;
        .menu-li {
          float: left;
          position: relative;
          label {
            display: block;
            height: 44px;
            min-width: 100px;
            text-align: center;
            line-height: 44px;
          }
          .menu-name-label {
            color: white;
          }
        }
        li:hover .nav {
          display: block;
        }
        li:hover .menu-name-label {
          background-color: rgba(255, 255, 255, 0.5);
        }
        .nav {
          display: none;
          position: absolute;
          top: 44px;
          left: 0;
          background-color: white;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
          label {
            border-top: 1px solid #efefef;
          }
          label:hover {
            color: #0066cc;
          }
        }
      }
    }
  }
  .page-middle-div {
    width: 100%;
    height: calc(100vh - 44px);
    display: flex;
    flex-direction: row;
    .page-menu-div {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      color: white;
      background-color: #d7d7d7;
      border-right: 1px solid #b1b1b1;
      .tree-menu-div {
        width: 259px;
        display: flex;
      }
      .tree-menu-first-div {
        height: 60px;
        font-size: 16px;
        line-height: 60px;
        background-color: #365aad;
        .menu-img-div {
          width: 74px;
          text-align: center;
          img {
            vertical-align: middle;
          }
        }
      }
      .tree-menu-second-div {
        height: 50px;
        font-size: 14px;
        line-height: 50px;
        background: #4477ca;
        .menu-txt-div {
          width: 100%;
          margin-left: 74px;
        }
      }
      .selected-second-menu-div {
        background: #99bce4;
      }
    }
    .page-menu-div-open {
      width: 259px;
      transition: width 0.5s;
    }
    .page-menu-div-hidden {
      width: 0;
      transition: width 0.5s;
    }
    .child-page-content-div {
      flex: 1;
      overflow: auto;
      .display-arrow-label {
        position: absolute;
        top: calc((100vh - 100px) / 2 + 44px);
        width: 17px;
        height: 61px;
        cursor: pointer;
        background: url("../assets/icon-arrow.png") no-repeat 0 0;
        display: block;
      }
      .display-arrow-label.open {
        background-position: 0 -61px;
      }
      .display-arrow-label:hover {
        background-position: right 0;
      }
      .display-arrow-label.open:hover {
        background-position: right -61px;
      }
      .child-page-view {
        width: calc(100% - 60px);
        margin: 30px auto 15px auto;
      }
    }
  }
}
</style>
